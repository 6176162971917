import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProjectProps } from "../slices/types";
import axios from "axios";

export const getProjects = createAsyncThunk(
  "projects/getProjects",
  async (): Promise<Array<ProjectProps>> => {
    const res = await axios.get(
      "https://lxndrbukin-github-io.vercel.app/apis/projects.json"
    );
    return res.data;
  }
);
