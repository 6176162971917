import { SkillProps } from '../../store';

export default function Skill({ name, icon }: SkillProps): JSX.Element {
  return (
    <li key={name} className="skills-list-item">
      <img src={icon} alt={name} />
      <span>{name}</span>
    </li>
  );
}
