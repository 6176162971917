import "./assets/styles.scss";
import HeaderLogo from "./HeaderLogo";
import HeaderLinks from "./HeaderLinks";

export default function Header(): JSX.Element {
  return (
    <header className="header-container">
      <div className="header">
        <HeaderLogo />
        <HeaderLinks />
      </div>
    </header>
  );
}
