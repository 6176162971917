import "./assets/styles.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  RootState,
  CertificationProps,
  DataProps,
  getCertifications,
} from "../../store";
import Certification from "./Certification";

export default function Certifications(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const { certifications } = useSelector(
    (state: RootState): DataProps => state.data
  );

  useEffect(() => {
    dispatch(getCertifications());
  }, [dispatch]);

  const placeholder = (
    <div className="certifications-grid-item placeholder"></div>
  );

  const placeholderArray = Array(3).fill(placeholder);

  const renderedCertifications = certifications.map(
    (certification: CertificationProps): JSX.Element => {
      return <Certification key={certification.name} {...certification} />;
    }
  );

  return (
    <section className="section certifications">
      <h1 className="header">Certifications</h1>
      <div className="certifications-grid">
        {renderedCertifications.length
          ? renderedCertifications
          : placeholderArray}
      </div>
    </section>
  );
}
