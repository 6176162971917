export default function HeaderLinks(): JSX.Element {
  return (
    <ul className="header-links">
      <li className="header-link">
        <a href="#about">About</a>
      </li>
      <li className="header-link">
        <a href="#projects">Projects</a>
      </li>
      <li className="header-link">
        <a href="#contact">Contact</a>
      </li>
    </ul>
  );
}
