import "./assets/styles.scss";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppDispatch,
  DataProps,
  RootState,
  SkillProps,
  getSkills,
} from "../../store";
import Skill from "./Skill";

export default function Skills(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const { skills } = useSelector((state: RootState): DataProps => state.data);

  useEffect(() => {
    dispatch(getSkills());
  }, [dispatch]);

  const placeholder = <li className="skills-list-item placeholder"></li>;

  const placeholderArray = Array(12).fill(placeholder);

  const renderedSkills = skills.map((skill: SkillProps): JSX.Element => {
    return <Skill key={skill.name} {...skill} />;
  });

  return (
    <section className="section skills">
      <h1 className="header">Skills</h1>
      <ul className="skills-list">
        {renderedSkills.length ? renderedSkills : placeholderArray}
      </ul>
    </section>
  );
}
