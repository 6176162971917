import { CertificationProps } from "../../store";

export default function Certification({
  name,
  certificate_url,
  url,
  stack,
}: CertificationProps): JSX.Element {
  const renderedStack = stack.map((tech: string): JSX.Element => {
    return <li key={tech}>{tech}</li>;
  });

  return (
    <div className="certifications-grid-item">
      <a rel="noreferrer" target="_blank" href={certificate_url}>
        <img src={certificate_url} alt={name} />
      </a>
      <div className="certification-info">
        <a rel="noreferrer" target="_blank" className="title" href={url}>
          <h3 title={name}>
            {name.length > 45 ? name.substring(0, 43) + "..." : name}
          </h3>{" "}
        </a>
        <ul>{renderedStack}</ul>
        <a rel="noreferrer" target="_blank" className="course-link" href={url}>
          <i className="fa-solid fa-arrow-up-right-from-square"></i>
        </a>
      </div>
    </div>
  );
}
