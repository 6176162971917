import "./assets/styles.scss";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppDispatch,
  DataProps,
  ProjectProps,
  RootState,
  getProjects,
} from "../../store";
import Project from "./Project";

export default function Projects(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const { projects } = useSelector((state: RootState): DataProps => state.data);

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  const placeholder = <div className="projects-grid-item placeholder"></div>;
  const placeholderArrray = Array(2).fill(placeholder);

  const renderedProjects = projects.map(
    (project: ProjectProps): JSX.Element => {
      return <Project key={project.name} {...project} />;
    }
  );

  return (
    <section id="projects" className="section projects">
      <h1 className="header">Projects</h1>
      <div className="projects-grid">
        {renderedProjects.length ? renderedProjects : placeholderArrray}
      </div>
    </section>
  );
}
