import "./assets/styles.scss";

export default function Footer(): JSX.Element {
  return (
    <footer className="footer-container">
      <div className="footer">
        <ul className="follow-links">
          <li className="follow-link">
            <a
              title="GitHub"
              rel="noreferrer"
              target="_blank"
              href="https://github.com/lxndrbukin"
            >
              <i className="fab fa-github"></i>
            </a>
          </li>
          <li className="follow-link">
            <a
              title="Instagram"
              rel="noreferrer"
              target="_blank"
              href="https://codepen.io/lxndrbukin"
            >
              <i className="fa-brands fa-codepen"></i>
            </a>
          </li>
          <li className="follow-link">
            <a
              title="LinkedIn"
              rel="noreferrer"
              target="_blank"
              href="https://www.linkedin.com/in/lxndrbukin/"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
          <li className="follow-link">
            <a
              title="Instagram"
              rel="noreferrer"
              target="_blank"
              href="https://www.instagram.com/lxndrbukin/"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </li>
        </ul>
        <span className="footer-copyright">
          lxndrbukin &copy; {new Date().getFullYear()}
        </span>
      </div>
    </footer>
  );
}
