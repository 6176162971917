import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  DataProps,
  SkillProps,
  ProjectProps,
  CertificationProps,
} from "./types";
import { getSkills } from "../thunks/getSkills";
import { getProjects } from "../thunks/getProjects";
import { getCertifications } from "../thunks/getCertifications";

const initialState: DataProps = {
  skills: [],
  projects: [],
  certifications: [],
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers: (builder): void => {
    builder.addCase(
      getSkills.fulfilled,
      (state: DataProps, action: PayloadAction<Array<SkillProps>>): void => {
        state.skills = action.payload;
      }
    );
    builder.addCase(
      getProjects.fulfilled,
      (state: DataProps, action: PayloadAction<Array<ProjectProps>>): void => {
        state.projects = action.payload;
      }
    );
    builder.addCase(
      getCertifications.fulfilled,
      (
        state: DataProps,
        action: PayloadAction<Array<CertificationProps>>
      ): void => {
        state.certifications = action.payload;
      }
    );
  },
});

export default dataSlice.reducer;
