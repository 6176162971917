import "./assets/styles.scss";

export default function Contact(): JSX.Element {
  return (
    <section id="contact" className="section contact">
      <h1>Would like to get in touch?</h1>
      <div className="contact-buttons">
        <a
          rel="noreferrer"
          target="_blank"
          href="mailto:job-offers@lxndrbukin.com"
        >
          <i className="fa-solid fa-envelope"></i> Email
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.linkedin.com/in/lxndrbukin/"
        >
          <i className="fa-brands fa-linkedin"></i> LinkedIn
        </a>
      </div>
    </section>
  );
}
