import { createAsyncThunk } from "@reduxjs/toolkit";
import { SkillProps } from "../slices/types";
import axios from "axios";

export const getSkills = createAsyncThunk(
  "skills/getSkills",
  async (): Promise<Array<SkillProps>> => {
    const res = await axios.get(
      "https://lxndrbukin-github-io.vercel.app/apis/skills.json"
    );
    return res.data;
  }
);
