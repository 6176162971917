import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCertifications = createAsyncThunk(
  "certifications/getCertifications",
  async () => {
    const res = await axios.get(
      "https://lxndrbukin-github-io.vercel.app/apis/certifications.json"
    );
    return res.data;
  }
);
