import "./assets/styles.scss";
import HelloThere from "../../assets/imgs/hellothere.png";

export default function About(): JSX.Element {
  return (
    <section id="about" className="section about">
      <div className="about-left">
        <div className="about-intro">
          <span className="about-intro"> Hi, I'm Alex</span>
          <span className="about-intro secondary">Front-end Developer</span>
        </div>
        <p className="about-text">
          I enjoy learning new things, contanstly improving my skills, creating
          new projects and learning new things. I enjoy listening to Rock Music,
          reading Tech-related books and learning new techologies and exploring
          the world of Web Development.
        </p>
        <div className="follow">
          <span>Follow me</span>
          <ul className="follow-links">
            <li className="follow-link">
              <a
                title="GitHub"
                rel="noreferrer"
                target="_blank"
                href="https://github.com/lxndrbukin"
              >
                <i className="fab fa-github"></i>
              </a>
            </li>
            <li className="follow-link">
              <a
                title="Instagram"
                rel="noreferrer"
                target="_blank"
                href="https://codepen.io/lxndrbukin"
              >
                <i className="fa-brands fa-codepen"></i>
              </a>
            </li>
            <li className="follow-link">
              <a
                title="LinkedIn"
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/in/lxndrbukin/"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
            <li className="follow-link">
              <a
                title="Instagram"
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/lxndrbukin/"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="about-right">
        <div className="about-img">
          <img src={HelloThere} alt="" />
        </div>
      </div>
    </section>
  );
}
