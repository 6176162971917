import Header from './Header/Header';
import About from './About/About';
import Skills from './Skills/Skills';
import Projects from './Projects/Projects';
import Certifications from './Certifications/Certifications';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

export default function App(): JSX.Element {
  return (
    <div className="app">
      <Header />
      <div className="container">
        <About />
        <Skills />
        <Projects />
        <Certifications />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
