import { ProjectProps } from "../../store";

export default function Project({
  name,
  production,
  desc,
  img,
  stack,
  urls,
}: ProjectProps): JSX.Element {
  const renderedStack = stack.map((tech) => {
    return <li key={tech}>{tech}</li>;
  });

  const blur = <div className="blur">In Development</div>;

  return (
    <div className="projects-grid-item">
      <a
        className="projects-grid-item-img"
        rel="noreferrer"
        target="_blank"
        title={name}
        href={urls.live}
      >
        {!production && blur}
        <img src={img} alt={name} />
      </a>
      <div className="projects-grid-item-info">
        <h2>{name}</h2>
        <ul>{renderedStack}</ul>
        <p dangerouslySetInnerHTML={{ __html: desc }} />
        <div className="project-links">
          <a
            rel="noreferrer"
            target="_blank"
            title="GitHub Repository"
            href={urls.github}
          >
            <i className="fa-brands fa-github"></i>
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            title="Live Page"
            href={urls.live}
          >
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
