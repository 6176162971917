import { configureStore } from "@reduxjs/toolkit";
import dataReducer from "./slices/dataSlice";

export const store = configureStore({
  reducer: {
    data: dataReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export * from "./slices/types";
export * from "./slices/dataSlice";
export * from "./thunks/getSkills";
export * from "./thunks/getProjects";
export * from "./thunks/getCertifications";
